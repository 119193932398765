import * as React from "react";
import { graphql } from "gatsby";
import BlocTitle from "../components/elements/bloc-title";
import BlocImage from "../components/elements/bloc-image";
import _map from "lodash/map";
import Layout from "../components/layout";

const QueensPage = ({ data }) => {
  const imageList = _map(data.allStrapiQueen.nodes, (d) => (
    <div className="w-1/3 lg:w-1/5">
      <a href={`/queen/${d.slug}`}>
        <BlocImage src={d.mainPhoto.url} />
      </a>
    </div>
  ));

  return (
    <Layout>
      <div className="bg-black font-gotham text-white ">
        <div className="-space-y-2 bg-yellow pt-2 pb-1 text-center">
          <BlocTitle
            styleName="text-xl lg:text-3xl font-gotham-ultra uppercase"
            content="Tous nos talents"
          />
        </div>
        <div className="flex flex-wrap justify-center gap-4 pt-4 pb-8 last:justify-center lg:gap-8 lg:gap-12 lg:py-12 lg:pt-16 lg:pt-10">
          {imageList}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query allStrapiQueenQuery {
    allStrapiQueen(sort: { fields: sortOrder, order: DESC }) {
      nodes {
        mainPhoto {
          url
        }
        slug
        firstName
        name
      }
    }
  }
`;
export default QueensPage;
